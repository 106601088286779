
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/agencies/Agencies.vue";
import ApiService from "@/core/services/ApiService";
import _ from "lodash";
import { showModal } from "@/core/helpers/dom";
import axios from "axios";
import {useRoute} from 'vue-router'
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "Citas",
  components: {
    Datatable,
    Field
  },
  setup() {
    const loading = ref<boolean>(false);
    const keywords = ref<string>("");
    const showing = ref<number>(10);
    const buscar = ref<string>("");
    const params = ref<any>();
    const headerConfig = ref([
      {
        name: "Prospecto",
        key: "prospecto",
        sortable: true,
      },
      {
        name: "Agencia",
        key: "agencia",
        sortable: true,
      },
      {
        name: "Tipo",
        key: "tipo",
        sortable: true,
      },
      {
        name: "Fecha",
        key: "fecha",
        sortable: true,
      },
      {
        name: "Vehículo",
        key: "vehiculo",
        sortable: true,
      },
      {
        name: "Estatus",
        key: "estatus",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      },
    ]);
    const tableData1 = ref<any>([]);
    const links = ref<any>([]);
    const total = ref<number>(0);
    const valuador = ref<any>("Valuador");
    const tipo = ref<any>("Tipo");
    const status = ref<any>("Estatus");
    const agencia = ref<any>("Agencia");
    const from = ref<number>(0);
    const to = ref<number>(0);
    const noResults = ref<boolean>(false);
    const agencies = ref<any>([{}]);
    const valuadores = ref<any>([{}]);
    const citaDetailModalRef = ref<null | HTMLElement>(null);
    const tipoRol = ref<string>("");
    const detailAppoinment = ref<any>({});
    const route=useRoute();

    const dtipo = ref<string>("");
    const dstatus = ref<string>("");
    const ddia = ref<string>("");
    const dhorario = ref<string>("");
    const dagencia = ref<string>("");
    const didagencia = ref<number>(0);
    const didvehicle = ref<number>(0);
    const dvehiculo = ref<string>("");
    const dkms = ref<string>("");
    const dduenios = ref<string>("");
    const dcliente = ref<string>("");
    const dmail = ref<string>("");
    const dphone = ref<string>("");
    const dprecio = ref<string>("");
    const dvaluador = ref<string>("");
    const dvehiclecuenta = ref<string>("");
    const dvehiclekmscuenta = ref<string>("");

    const types = ref<any>([{ id:"Tipo", name:"Tipo" }, { id:1, name:"Avalúo en agencia"}, { id:2, name:"Venta" }]);
    const statuses = ref<any>([{ id:"Estatus", name:"Estatus" }, { id:1, name:"Pendiente" }, { id:2, name:"Atendida" }, { id:3, name:"Cancelada" }]);
    
    /**
     * @method clearData
     * @params none
     * @return Clear data of table
     */ 
    const clearData = () => {
      tableData1.value.splice(0);
      links.value.splice(0);
      total.value = 0;
      from.value = 0;
      to.value = 0;
    }

    /**
     * @method getPagination
     * @params none
     * @return Leads with pagination
     */
    const getPagination = async (link) => {
      clearData();
      let urlProd = link.url;
      if(urlProd.search('seminuevossolana') > 0){
        urlProd = urlProd.replace('http', 'https');
      }
      getCitas(urlProd);
    };

    /**
     * @method showingSet
     * @params none
     * @return Leads with data show by quantity
     */
    const showingSet = async () => {
      clearData();
      getCitas(params.value);
    };

    /**
     * @method getAgencies
     * @params none
     * @return Agencies 
     */
    const getAgencies = async () => {
      const {data} = await ApiService.get("api/agencies?showing=100");
      agencies.value = data.data.agency.data;
    }

    /**
     * @method getValuadores
     * @params none
     * @return Valuadores list
     */
    const getValuadores = async () => {
      const {data} = await ApiService.get("api/users/role/valuador");
      valuadores.value = data.data;
    }

    /**
     * @method searching
     * @params word, valuador, tipo, estatus, agencia
     * @return Valuadores list
     */
    const searching = async (report = false ) => {
      let url = "";
      if(buscar.value != ""){
        url += "&search="+buscar.value;
      }
      if(valuador.value != "Valuador"){
        url += "&valuator="+valuador.value;
      }
      if(tipo.value != "Tipo"){
        url += "&type="+tipo.value;
      }
      if(status.value != "Estatus"){
        // (status.value == "Pendiente")? status.value = 1 : false;
        // (status.value == "Atendida")? status.value = 2 : false;
        // (status.value == "Cancelada")? status.value = 3 : false;
        url += "&status="+status.value;
      }
      if(agencia.value != "Agencia"){
        url += "&agency="+agencia.value;
      }

      params.value = url;
      
      if(report){
        let urlReport = "api/leads/citas/agencia?showing="+showing.value;
        urlReport += url += "&report=1";
        const { data } = await axios.get(urlReport);
        let csvContent = "data:text/csv;charset=utf-8," + data;
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "ListadoCitas.csv");
        link.click();
      }else{
        
        clearData();
        getCitas(url);
      }
    }

    /**
     * @method searchDebouncer
     * @params none
     * @return appointments 
     */
    const searchDebouncer = _.debounce(()=>{
     searching();
    },500);

    /**
     * @method setStatus
     * @params none
     * @return appointments 
     */
    const setStatus = () => {
      searching();
    };

    /**
     * @method setAgencia
     * @params none
     * @return appointments 
     */
    const setAgencia = () => {
      searching();
    };

      /**
     * @method setValuador
     * @params none
     * @return appointments 
     */
    const setValuador = () => {
      searching();
    };

      /**
     * @method setTipo
     * @params none
     * @return appointments 
     */
    const setTipo = () => {
      searching();
    };

    /**
     * @method getCitas
     * @params url: string
     * @return Appointments list
     */
    const getCitas = async (url = "") => {
      let urlOrigin = "api/leads/citas/agencia?showing="+showing.value;
      if(route.params.tipo !== undefined){
        tipo.value = parseInt(route.params.tipo.toString());
        urlOrigin += "&type="+route.params.tipo;
      }
      if(route.params.status !== undefined){
        status.value = parseInt(route.params.status.toString());
        urlOrigin += "&status="+route.params.status;
      }
      if(route.params.agencia !== undefined){
        agencia.value = parseInt(route.params.agencia.toString());
        urlOrigin += "&agency="+route.params.agencia; 
      }
        
      if(url != "" && !url.includes("http")){
        urlOrigin += url;

      }
      if(url != "" && url.includes("http")){
        urlOrigin = url;
      }
      const {data} = await ApiService.get(urlOrigin);
      console.log(data);
      let citas = data.data.data;
      if(citas.length > 0){
        citas.forEach((val) => {
          let fecha = val.fecha.split('-');
          tableData1.value.push({
            id: val.id,
            prospecto: val.prospecto,
            agencia: val.agencia,
            tipo: val.tipo,
            fecha: fecha[2]+"/"+fecha[1]+"/"+fecha[0],
            vehiculo: val.vehicle,
            estatus: val.status
          });
        });
        links.value = data.data.links;
        total.value = data.data.total;
        from.value = data.data.from;
        to.value = data.data.to;
        noResults.value = false;
      }else{
        noResults.value = true;
      }
    }

    /**
     * @method formatPhone
     * @params string phone
     * @return phone with format ##-##-##-##-##
     */
    const formatPhone = (phone) => {
      let newPhone = "";
      for (var j = 0; j < phone.length; j++) {
        if(isNumber(phone.charAt(j))){
          newPhone += phone.charAt(j);
        }
      }
      let newFormat = "";
      for (var i = 0; i < newPhone.length; i++) {
        /* let ni = i + 1;
        if(ni % 2 == 0){
          newFormat += newPhone.charAt(i) + "-";
        }else{
          newFormat += newPhone.charAt(i);
        } */
        newFormat += newPhone.charAt(i);
        if(i == 2 || i == 5){
          newFormat += "-";
        }
      }
      return newFormat;
    };

    const isNumber = (char) =>  {
      return /^\d$/.test(char);
    }

    /**
     * @method formatPrice
     * @params price
     * @return price with currency format
     */
    const formatPrice = (value) => {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    /**
     * @method openModal
     * @params none
     * @return Open detail appointment modal
     */
    const openModal = async (id) => {
      const {data} = await ApiService.get("api/citas/"+id);
      detailAppoinment.value = await data.data;
      dtipo.value = detailAppoinment.value.tipo_cita.name;
      dstatus.value = detailAppoinment.value.status.name;
      ddia.value = detailAppoinment.value.dia[0];
      dhorario.value = detailAppoinment.value.horario[0];
      dagencia.value = detailAppoinment.value.agency != undefined ? detailAppoinment.value.agency.name: "";
      didagencia.value = detailAppoinment.value.agency != undefined ? detailAppoinment.value.agency.id: 0;
      if(detailAppoinment.value.tipo_cita.id == 2){
        dvehiculo.value = detailAppoinment.value.vehicle_de_interes.brand + " " +  detailAppoinment.value.vehicle_de_interes.model + " " + detailAppoinment.value.vehicle_de_interes.version + " " + detailAppoinment.value.vehicle_de_interes.year;
        dkms.value = formatPrice(detailAppoinment.value.vehicle_de_interes.kms).slice(0,-3);
        dduenios.value = "0";
        didvehicle.value = detailAppoinment.value.vehicle_de_interes.id;
        if(detailAppoinment.value.vehicle != undefined){
          dvehiclecuenta.value = detailAppoinment.value.vehicle.vehiculo;
          dvehiclekmscuenta.value = detailAppoinment.value.vehicle.kms;
        }else{
          dvehiclecuenta.value = "";
          dvehiclekmscuenta.value = "";
        }
      }else{
        dvehiculo.value = detailAppoinment.value.vehicle.brand + " " + detailAppoinment.value.vehicle.model + " " + detailAppoinment.value.vehicle.version + " " + detailAppoinment.value.vehicle.year;
        dkms.value = formatPrice(detailAppoinment.value.vehicle.kms).slice(0,-3);
        dduenios.value = detailAppoinment.value.vehicle.owners;
        dvehiclecuenta.value = "";
        dvehiclekmscuenta.value = "";
      }
      dcliente.value = detailAppoinment.value.cliente.name;
      dmail.value = detailAppoinment.value.cliente.mail;
      dphone.value = formatPhone(detailAppoinment.value.cliente.phone);
      dprecio.value = detailAppoinment.value.precio_cotizado != undefined ? formatPrice(detailAppoinment.value.precio_cotizado.price) : "";
      dvaluador.value = detailAppoinment.value.valuador != undefined ? detailAppoinment.value.valuador.name : "";
      showModal(citaDetailModalRef.value);
    }

    /**
     * @method getRol
     * @params none
     * @return get user type rol
     */
    /* const getRol = async () => {
      const {data} = await ApiService.get(process.env.VUE_APP_API_URL + "/api/auth/user");
      tipoRol.value = data.data[0].type;
    }; */

    onMounted(async () => {
      //await getRol();
      await getAgencies();
      await getValuadores();
      await getCitas();
    });

    return {
      headerConfig,
      loading,
      keywords,
      tableData1,
      links,
      getPagination,
      total,
      buscar,
      showing,
      showingSet,
      from,
      to,
      noResults,
      getCitas,
      clearData,
      agencies,
      getAgencies,
      getValuadores,
      valuadores,
      searchDebouncer,
      searching,
      valuador,
      setValuador,
      tipo,
      setTipo,
      status,
      setStatus,
      agencia,
      setAgencia,
      citaDetailModalRef,
      openModal,
      tipoRol,
      //getRol,
      detailAppoinment,
      dtipo,
      dstatus,
      ddia,
      dhorario,
      dagencia,
      dvehiculo,
      dkms,
      dduenios,
      dcliente,
      dmail,
      dphone,
      dprecio,
      dvaluador,
      formatPhone,
      formatPrice,
      didagencia,
      isNumber,
      didvehicle,
      dvehiclecuenta,
      dvehiclekmscuenta,
      route,
      params,
      types,
      statuses,
    };
  },
});
